/* Footer.css */
body,
html {
    height: 100%;
    margin: 0;
}

body {
    display: flex;
    flex-direction: column;
}

.sticky-footer {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 1rem;
    position: sticky;
    bottom: 0;
    width: 100%;
}